/*
 * graphql.tsx
 * emilien <emilien@emilien-pc>, 2020
 */


import store from './store'

const gql = (strings : any) : string => strings.raw[0];

let mutation : any = {
	ADD_CLIENT: gql`mutation addClient($name: String!, $email: String!, $password: String!) {
		addClient(name: $name, email: $email, password: $password)
	}`,

	CONNECT_CLIENT: gql`mutation connectClient($email: String!, $password: String!) {
		connectClient(email: $email, password: $password)
	}`,

	REQUEST_API_TOKEN: gql`mutation requestApiToken {
		requestApiToken
	}`,

	GET_SELF: gql`query getSelfClient {
		getSelfClient {
			name, email
		}
	}`,

	ALL_PHOTOS: gql`query getPhotos {
		allPhotos {
			id,
			name,
			commentsLen,
			path,
			createdAt,
			uploadedBy {
				id,
				name
			}
		}
	}`,

	CLIENT_PHOTOS: gql`query getClientPhotos($id: Int) {
		client(id: $id)	{
			clientPhotos {
				id,
				name,
				commentsLen,
				path,
				createdAt,
				uploadedBy {
					id,
					name
				}
			}
		}
	}`,

	ADD_COMMENT: gql`mutation addComment($content: String!, $photo_id: Int!) {
		addComment(content: $content, photoId: $photo_id) {
			id,
			content,
			createdAt,
			uploadedBy {
				id, name
			},
		}
	}`,

	GET_COMMENTS: gql`query getComments($photo_id: Int!) {
		getPhoto(id: $photo_id) {
			id,
			comments {
				id,
				content,
				createdAt,
				uploadedBy {
					id, name
				},
			}

		}
	}`,

	REPORT_PHOTO: gql`mutation reportPhoto($photo_id: Int!) {
		reportPhoto(photoId: $photo_id)
	}`,

	GET_USER_WITH_NAME: gql`query getUser($username: String!) {
		client(username: $username) {
			id, name
			clientPhotos {
				id,
				name,
				commentsLen,
				path,
				createdAt,
				uploadedBy {
					id,
					name
				}
			}
		}
	}`,

	GET_USER_BOX: gql`query getUserBox {
		client {
			clientBox {
				machineId, country, region, city
			}
		}
	}`,

	ADD_CONTACT: gql`mutation addContact($content: String!, $type: String!, $email: String!) {
		addContact(content: $content, questionType: $type, email: $email)
	}`

};

let GQL = (name : keyof typeof mutation, variables?: object) : Promise<any> => {
	return new Promise((resolve, reject) => {
		const token = store.permanent('token').get()

		fetch(`http://${ window.location.hostname }:8080/graphql`, {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			},
			redirect: 'error',
			body: JSON.stringify({
				query: mutation[name],
				variables,
			})
		})
		.then(r => r.json())
		.then(({ data, errors }) => {
			if (data === null)
				reject(errors);
			else
				resolve(data);
		})
		.catch(e => reject(e))
	})
}

type fp = (arg: Array<string>) => void;
type Dictionary = { [index: string]: fp | { [index: string]: fp } };

function GQLerror(errors : Array<any>, o : Dictionary) {
	console.log(errors);
	for (let it of errors) {
		let error : Array<string> = it.message.split(':');

		let v = o[error[0]];

		if (v === undefined) {
			console.error('Error case not catched:', it.message);
		} else if (typeof v === 'function') {
			v(error.splice(1));
		} else {
			v = v[error[1]];

			if (v === undefined) {
				console.error('Error case not catched:', it.message);
				return;
			}
			v(error.splice(1));
		}
	}
}

export { GQL, GQLerror };
