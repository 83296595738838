/*
 * App.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React, { ReactElement, Suspense } from 'react';

import { Header } from './Global/Header';
import { Footer } from './Global/Footer';
import { Loading } from './Global/Loading';

import { Middleware, middlewares } from './Global/Middlewares';
import './Global/graphql';

import './Global/app.scss';

import { Bug } from './Global/Bug';

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

const lazy_import = (component : string) => {
	return React.lazy(() => import(`./${ component }/${ component }`)
					  .then(module => ({ default: module[component] })))
}

const Landing = lazy_import('Landing');
const Gallery = lazy_import('Gallery');
const UserGallery = lazy_import('UserGallery');
const Contact = lazy_import('Contact');
const License = lazy_import('License');
const About = lazy_import('About');
const Installation = lazy_import('Installation');
const Settings = lazy_import('Settings');
const Map = lazy_import('Map');


const StRoute = ({ path, component } : { path: string, component: ReactElement }) => {
	return (
		<Route exact path={ path }>
			<Suspense fallback={ <Loading /> }>
				{ component }
			</Suspense>
		</Route>
	)
}

const App: React.FC = () => {
	return (
		<>
			<Router>
				<Header />
				<Switch>
					<StRoute path="/gallerie" component={ <Gallery /> } />
					<StRoute path="/user/:username" component={ <UserGallery /> } />
					<StRoute path="/contact" component={ <Contact /> } />
					<StRoute path="/map" component={ <Map /> } />
					<StRoute path="/license" component={ <License /> } />
					<StRoute path="/a_propos" component={ <About /> } />
					<StRoute path="/installation" component={ <Installation /> } />
					<StRoute path="/settings" component={
						<Middleware middlewares={[ middlewares.isConnected ]} redirect="/">
							<Settings />
						</Middleware>
						} />
					<StRoute path="/*" component={ <Landing /> } />
				</Switch>
				<Bug />
				<Footer />
			</Router>
		</>
	);
}

export default App;
