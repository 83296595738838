/*
 * Middlewares.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React from 'react';

import store from './store';

import { Redirect } from 'react-router-dom'


interface T {
	children: React.ReactNode,
	middlewares: Array<() => boolean>,
		redirect?: string
}

const Middleware: React.FC<T> = ({ middlewares, children, redirect }) => {
	for (let it of middlewares) {
		if (it() === false) {
			if (redirect)
				return <Redirect to={redirect} />
			return <></>
		}
	}

	return <>{ children }</>;
}

const middlewares = {
	isConnected: () => {
		if (!store.permanent('token').get())
			return false;
		return true;
	}
}

export { Middleware, middlewares };
