/*
 * Loading.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React from 'react';

import './Loading.scss'

const Loading: React.FC<{ shown?: boolean }> = ({ shown=true }) => {
	if (shown === true) {
		return (
			<div style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
				<svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</div>
		);
	} else {
		return <></>;
	}
};

export { Loading };
