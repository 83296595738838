/*
 * Header.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React, { useState, useEffect } from 'react';

import { Link } from "react-router-dom";

import logo from '../assets/viabird.png';
// import google from '../assets/google.png';

import gallery from '../assets/gallery.svg';
//import contact from '../assets/contact.svg';
import user from '../assets/user.svg';
import store from './store';
import { Dropdown, Field } from '../Dropdown/Dropdown';

import { GQL } from './graphql';
import Gear from '../assets/gear.obj.svg';
import Logout from '../assets/logout.obj.svg';
import { useHistory } from "react-router-dom";

import { Connection } from './Connection';


const UsernameArea : React.FC<{}> = () => {
	const [ name, setName ] = useState<string>('...');

	useEffect(() => {
		let u = store.permanent('token').listen(() => {
			GQL('GET_SELF').then(data => {
				console.log('received USER: ', data);
				store.volatile('user_data').dispatch(data.getSelfClient);
				setName(data.getSelfClient.name)
			}).catch(e => {
				store.permanent('token').dispatch(null);
			});
		}, true);

		return u;
	}, [])

	return <span>{ name }</span>
}

const UserDropdown: React.FC = () => {

	const [ shown, setShown ] = useState(false);
	const history = useHistory();

	return (
		<div onClick={ () => setShown(!shown) } className="vb_user_dropdown">
			<img src={ user } alt="" style={{ height: 23, marginRight: 12 }} />
			<UsernameArea />
			<Dropdown style={{ top: 53, right: 0, minWidth: 200 }} shown={ shown } close_dropdown={ () => setShown(false) }>
				<Field onClick={ () => history.push('/settings') }>
					<span className="vb_svg_field_icon">
						<Gear />
					</span>
					<p>Paramètres</p>
				</Field>
				<Field onClick={ () => {
					store.permanent('token').dispatch(null);
					history.push('/');
				} }>
					<span className="vb_svg_field_icon">
						<Logout />
					</span>
					<p>Déconnection</p>
				</Field>
			</Dropdown>
		</div>
	);
}

const Header: React.FC = () => {
	const [ token, setToken ] = useState(store.permanent('token').get() || null);
	const [ scroll, setScroll ] = useState(false);

	useEffect(() => {
		let unsub : Array<() => any> = []

		unsub.push(store.permanent('token').listen(token => {
			setToken(token)
		}));

		store.bridge('header_scroll').listen((scroll : boolean) => {
			setScroll(scroll);
		});

		return () => { for (let it of unsub) { it() } }
	}, [])

	const setModal = () => {
		store.bridge('show_connection_modal').dispatch(true);
	}

	return (
		<div className={`vb_header ${ scroll ? 'vb_scroll' : '' }`}>
			<Link to="/" style={{ height: '100%' }}>
				<img alt="" src={ logo } style={{ height: 'calc(100% - 30px)', padding: 15 }} />
			</Link>
			<div className="vb_header_menu" style={{ margin: 'auto', paddingTop: 20 }}>
				<Link to="/gallerie">
					<img src={ gallery } style={{ height: 25, marginRight: 12 }} alt="" />
					<span>Gallerie</span>
				</Link>
				<Link to="/installation">
					<span>Installation</span>
				</Link>
				<Link to="/map">Carte</Link>
			</div>
			{ (() => {
				if (token === null) {
					return (
						<>
							<div className="vb_connect_google" onClick={ () => { setModal() } } style={{ marginRight: 50, marginTop: 20, cursor: 'pointer' }}>
								{/* <img alt="" src={ google } /> */}
								<p>Se connecter</p>
							</div>
							<Connection />
						</>
					)
				} else {
					return <UserDropdown />
				}
			})() }
		</div>
	);
}

export { Header };
