/*
 * Messages.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React from 'react';

import Close from '../assets/close.obj.svg';

interface IClosableMessage {
	content: string | null | undefined,
	style?: object,
	type: string,
	cb: () => void
}
const ClosableMessage : React.FC<IClosableMessage> = ({ content, style, type, cb }) => {
	if (content) {
		return (
			<div style={ style } className={`vb_closable_message vb_message_${ type }`}>
				<div>
					<div>
						<span>{ content }</span>
						<div onClick={ () => cb() }>
							<Close />
						</div>
					</div>
				</div>
			</div>

		);
	} else {
		return <></>
	}
}

export { ClosableMessage };
