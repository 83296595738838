/*
 * Footer.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React from 'react';

import { Link } from "react-router-dom";

import labfab from '../assets/labfab.png';
import epitech from '../assets/epitech.png';

const Footer: React.FC = () => {
	return (
		<div className="vb_footer">
			<img src={ labfab } alt="" style={{ width: 30, height: 30 }} />
			<img src={ epitech } alt="" style={{ width: 22 }} />
			<span>Projet distribué sous les termes de la <Link to="/license">license MIT</Link>.</span>
			<Link to="/a_propos">À propos</Link>
			<Link to="/contact">Nous contacter</Link>
		</div>
	);
}

export { Footer };
