/*
 * Dropdown.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React, { ReactElement } from 'react';

import './Dropdown.scss';

interface IDropdown {
	style?: object,
	children?: Array<ReactElement>,
	shown: boolean,
	close_dropdown: () => void
};

const Dropdown: React.FC<IDropdown> = ({ style, children, shown, close_dropdown }) => {

	//useEffect(() => {
	//document.addEventListener('click', () => {
	//close_dropdown();
	//})
	//}, []);

	if (shown === true) {
		return (
			<div className="vb_dropdown" style={ style }>
				{ children }
			</div>
		);
	} else {
		return <></>;
	}
}

interface IField {
	children: ReactElement | Array<ReactElement>,
	onClick?: () => void
}

const Field: React.FC<IField> = ({ children, onClick }) => {
	return (
		<>
			<div className="vb_field" onClick={ onClick }>
				{ children }
			</div>
			<hr />
		</>
	)
}
export { Dropdown, Field };
