/*
 * Bug.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React from 'react';
import { Link } from 'react-router-dom';

import BugIcon from '../assets/bug.obj.svg';

const Bug: React.FC = () => {
	return (
		<Link to='/contact?type=bug' style={{ position: 'fixed', zIndex: 4000, bottom: 70, left: 30 }}>
			<div className="vb_bug">
				<BugIcon />
				<span className="vb_bug_span animated"> Reporter un bug </span>
			</div>
		</Link>
	);
}

export { Bug };
