/*
 * RightPanel.tsx
 * emilien <emilien@emilien-pc>, 2020
 */

import React, { useState, useEffect } from 'react';
import store from './store';

import arrow from '../assets/left-arrow.svg';

import { ClosableMessage } from './ClosableMessage';
import { GQL } from './graphql';
//import { useMutation } from '@apollo/react-hooks';
import { Loading } from './Loading'

import { GQLerror } from './graphql';

interface T { style?: object, value?: string, name: string, type?: string, change: (value: string) => any };
const LoginField : React.FC<T> = ({ style, name, value, type='text', change }) => (
	<div className="vb_input" style={ style }>
		<label>{ name }</label>
		<input onChange={ e => change(e.target.value) } name={ name } type={ type } placeholder="" value={ value } />
	</div>
)

interface X { click: () => any, style: object, text: string }
const LoginButton : React.FC<X> = ({ click, style, text }) => (
	<div onClick={ click } className="vb_rounded_button vb_small" style={ style }>
		<span>{ text }</span>
	</div>
)

const RegisterPage : React.FC<{ error?: string, form: any, cb: (form : object) => void }> = ({ error, form, cb }) => (
	<>
		<ClosableMessage content={ error } style={{ bottom: 'calc(100% + 28px)' }} type="error" cb={ () => store.bridge('connection_error').dispatch(null) } />
		<div className="register-return" onClick={ () => store.bridge('connection_page').dispatch(0) } >
			<img src={ arrow } alt="" style={{ width: 15 }} />
			<span style={{ marginLeft: 6 }}>Retour connection</span>
		</div>
		<h2 style={{ textAlign: 'center' }}>Inscription</h2>
		<LoginField change={ username => cb({ username }) } name="Nom de compte" value={ form.username } />
		<LoginField change={ email => cb({ email }) } name="Email" value={ form.email } type="email" />
		<LoginField change={ password => cb({ password }) } name="Mot de passe" value={ form.password } type="password" />
		<LoginField change={ repeatPassword => cb({ repeatPassword }) } name="Répéter votre Mot de passe" type="password" />
		<LoginButton click={ () => store.bridge('register').dispatch(form) } style={{ backgroundColor: '#C0641F', margin: '40px 30px 0' }} text="Inscription" />
	</>
);


const LoginPage : React.FC<{ error?: string, form: any, cb: (form : object) => void }> = ({ error, form, cb }) => (
	<>
		<ClosableMessage content={ error } style={{ bottom: 'calc(100% + 28px)' }} type="error" cb={ () => store.bridge('connection_error').dispatch(null) } />
		<h2 style={{ textAlign: 'center' }}>Bienvenue !</h2>
		<LoginField style={{ marginTop: 25 }} change={ email => cb({ email }) } name="Email" value={ form.email } type="email" />
		<LoginField change={ password => cb({ password }) } name="Mot de passe" value={ form.password } type="password" />
		<LoginButton click={ () => store.bridge('login').dispatch(form) } text="Se connecter"  style={{ backgroundColor: '#636fff', margin: '40px 30px 0' }} />
		<hr style={{ margin: 40 }} />
		<LoginButton click={ () => store.bridge('connection_page').dispatch(1) } style={{ backgroundColor: '#C0641F', margin: '0px 30px 0' }} text="Inscription" />
	</>
);

const ConnectionContent : React.FC<{}> = () => {
	const [ form, setForm ] = useState({
		email: '', username: '',
		password: '', repeatPassword: ''
	});

	// 0 -> Login, 1-> Register, 2 -> Loading
	const [ page, setPage ] = useState(0);
	const [ error, setError ] = useState<string | undefined>(undefined);

	//const [ addClient ] = useMutation(Mutations.get('ADD_CLIENT'));
	//const [ connectClient ] = useMutation(Mutations.get('CONNECT_CLIENT'));

	useEffect(() => {
		let unsub = [

			store.bridge('connection_page').listen(x => setPage(x)),

				store.bridge('login').listen(({ email, password }) => {
				setPage(2);
				GQL('CONNECT_CLIENT', { email, password}).then(client => {
					console.log('there');
					store.permanent('token').dispatch(client.connectClient);
					console.log('dispatched');
					//store.bridge('show_connection_modal').dispatch(false);
				}).catch(e => {
					GQLerror(e, {
						'client': {
							'INVALID_LOGIN': () => setError('Nom de compte ou mot de passe invalide')
						}
					});

					setPage(0);
				});
			}),

			store.bridge('register').listen(({ username, email, password, repeatPassword }) => {
				setError('')
				setPage(2);
				GQL('ADD_CLIENT', { name: username, email, password }).then(client => {
					store.permanent('token').dispatch(client.addClient);
				}).catch(e => {
					GQLerror(e, {
						'client': {
							'DUPLICATE_NAME': () => setError('Le nom de compte spécifié est associé a un compte existant'),
								'DUPLICATE_EMAIL': () => setError('L\'addresse email spécifié est associé a un compte existant'),
						}
					});

					console.log('register????');

					setPage(1);
				});
			}),

			store.bridge('connection_error').listen(() => {
				setError(undefined);
			})
		];

		return store.clear(unsub);
	}, [])

	if (page === 0)
		return <LoginPage error={ error } form={ form } cb={ v => setForm({ ...form, ...v }) } />
	else if (page === 1)
		return <RegisterPage error={ error } form={ form } cb={ v => setForm({ ...form, ...v }) } />
	else
		return <Loading />
}

const Connection : React.FC<{}> = () => {
	const [ shown, setShown ] = useState(false);

	useEffect(() => {
		let unsubscribe = store.bridge('show_connection_modal').listen((is_shown : boolean) => {
			setShown(is_shown);
		});

		return () => unsubscribe();
	}, [])

	return (
		<div onClick={ () => setShown(false) } className="vb_connection_bg" style={{
			backgroundColor: shown ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0)',
			pointerEvents: shown ? 'initial' : 'none'
			}}>
			{ (() => {
				if (shown === true) {
					return (
						<div onClick={ e => e.stopPropagation() } className="vb_connection">
							<ConnectionContent />
						</div>
					);
				}
			})() }
		</div>
	);

}

export { Connection };
